<template>
  <div>
    <h1>Profile</h1>
    <div class="mb-5 row">
      <h3 class="col-md-4 text-md-right align-self-start pt-3">Details</h3>
      <div class="col-md-6">
        <div class="card">
          <div class="card-body">
            <form v-on:submit.prevent="updateProfile">
              <div class="mb-2 row">
                <label for="name" class="col-md-4 col-form-label text-md-right">Name</label>
                <div class="col-md-6">
                  <input id="name" type="text" class="form-control" v-bind:class="{ 'is-invalid': errors.name }" name="name" v-model="member.name" required autocomplete="name" />
                  <div v-if="errors.name" class="alert alert-danger">
                    <div v-for="error in errors.name" :key="error">{{ error }}</div>
                  </div>
                </div>
              </div>
              <div class="mb-2 row">
                <label for="email" class="col-md-4 col-form-label text-md-right">E-Mail Address</label>
                <div class="col-md-6">
                  <input id="email" type="email" class="form-control" v-bind:class="{ 'is-invalid': errors.email }" name="email" v-model="member.email" required autocomplete="email" />
                  <div v-if="errors.email" class="alert alert-danger">
                    <div v-for="error in errors.email" :key="error">{{ error }}</div>
                  </div>
                </div>
              </div>
              <div class="mb-2 row">
                <label for="phone" class="col-md-4 col-form-label text-md-right">Phone</label>
                <div class="col-md-6">
                  <input id="phone" type="text" class="form-control" v-bind:class="{ 'is-invalid': errors.phone }" name="phone" v-model="member.phone" required autocomplete="phone" />
                  <div v-if="errors.phone" class="alert alert-danger">
                    <div v-for="error in errors.phone" :key="error">{{ error }}</div>
                  </div>
                </div>
              </div>
              <div class="text-right mb-2">
                <button class="btn btn-success">Save changes</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="mb-5 row">
      <h3 class="col-md-4 text-md-right align-self-start pt-3">Password</h3>
      <div class="col-md-6">
        <div class="card">
          <div class="card-body">
            <form v-on:submit.prevent="setPassword">
              <div class="mb-2 row">
                <label for="name" class="col-md-4 col-form-label text-md-right">New password</label>
                <div class="col-md-6">
                  <input id="password" type="password" class="form-control" v-bind:class="{ 'is-invalid': errors.password }" name="password" v-model="password.password" required />
                </div>
              </div>
              <div class="mb-2 row">
                <label for="email" class="col-md-4 col-form-label text-md-right">Repeat password</label>

                <div class="col-md-6">
                  <input id="password_confirmation" type="password" class="form-control" v-bind:class="{ 'is-invalid': errors.password }" name="password_confirmation" v-model="password.password_confirmation" required />
                </div>
              </div>
              <div v-if="errors.password" class="alert alert-danger mb-2">
                <div v-for="error in errors.password" :key="error">{{ error }}</div>
              </div>
              <div class="text-right mb-2">
                <button class="btn btn-success">Update password</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      member: {},
      password: {
        password: "",
        password_confirmation: "",
      },
      errors: {},
    };
  },
  async mounted() {
    const { data } = await this.$http.get("/member");
    this.member = data;
  },
  methods: {
    updateProfile() {
      this.errors = {};
      this.$http
        .put("/profile", this.member)
        .then((response) => {
          localStorage.setItem("member", JSON.stringify(response.data));
          this.$emit("refresh");
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    setPassword() {
      this.errors = {};
      this.$http.put("/password", this.password).catch((error) => {
        this.errors = error.response.data.errors;
      });
    },
  },
};
</script>